<template>
  <div class="w-100 px-2 mt-5">
    <v-container fluid class="pt-0 pb-15 d-flex flex-column">
      <div class="d-flex flex-row align-center justify-between mb-3">
        <span class="white--text font-30 app-medium-font ml-5">{{
          $t("ads")
        }}</span>
        <v-btn color="#B5E539" small @click="onCreateAds" class="mt-3">
          Create
        </v-btn>
      </div>
      <div>
        <div v-for="ad in ads" :key="ad._id">
          <ads-card :ads="ad" class="mb-4" />
        </div>
      </div>
      <confirm-delete-dialog
        :deleteDialog="deleteDialg"
        :onClose="onCloseConfirmDelete"
        :onConfirm="onConfirmDelete"
      />
      <admin-add-ads-dialog
        :dialog="addDialog"
        :onClose="onCloseDialog"
        v-if="addDialog"
      />
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import dateFormat from "dateformat";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
import AdminAddAdsDialog from "./AdminAddAdsDialog.vue";
import AdsCard from "@/components/ads/AdsCard.vue";
export default {
  components: {
    ConfirmDeleteDialog,
    AdminAddAdsDialog,
    AdsCard
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "", value: "image" },
        { text: "Name", value: "name" },
        { text: "About", value: "about" },
        { text: "Website", value: "website" },
        { text: "Created At", value: "createdAt" },
        { text: "Actions", value: "actions" }
      ],
      deleteDialg: false,
      selected: null,
      expanded: [],
      editDialog: false,
      addDialog: false,
      snackbar: false,
      errorMessage: null
    };
  },
  methods: {
    ...mapActions("ads", {
      fetchAllAds: "fetchAllAds",
      deleteAds: "deleteAds"
    }),
    onSelectClient(item) {
      console.log(item);
    },
    formatDate(date) {
      return dateFormat(this.convertTZ(date, this.timezone), "mm/dd/yyyy");
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York"
          }
        )
      );
    },
    onEditAds(item) {
      this.selected = item;
      this.editDialog = true;
    },
    onCloseDialog() {
      this.addDialog = false;
      this.editDialog = false;
    },
    onDeleteAds(item) {
      this.selected = item;
      this.deleteDialg = true;
    },
    onCloseConfirmDelete() {
      this.deleteDialg = false;
    },
    onConfirmDelete() {
      this.deleteDialg = false;
      this.loading = true;
      this.deleteAds({ _id: this.selected._id })
        .then(() => {
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    onCreateAds() {
      this.addDialog = true;
    }
  },
  computed: {
    ...mapGetters({
      ads: "ads/getAllAds",
      profile: "auth/getProfile",
      profileType: "auth/getType",
      timezone: "usersetting/getTimeZone"
    })
  },
  mounted() {
    if (this.profileType != "Admin") {
      this.$router.push({ name: "home" });
    }
    this.loading = true;
    this.fetchAllAds()
      .then(() => {
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  }
};
</script>
<style scoped>
.page-title {
  color: #472583;
  font-size: 40px;
  font-weight: bold;
}
.title-line {
  height: 2px;
  width: 100%;
  background-color: #472583;
}
</style>
